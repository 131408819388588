import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import lazyWithRetry from './utils/lazyWithRetry';
import EmergePayProvider from './context/EmergePayContext/EmergePayContext';
import { routes } from './utils/routes';

const HomeFallbackPage = lazyWithRetry(() => import('@/pages/common/Fallback'), 'home-fallback');
const Home = lazy(() => import('@/pages/Home'));
const Success = lazyWithRetry(() => import('@/pages/Success'), 'success');
const CancelledPage = lazyWithRetry(() => import('@/pages/Cancelled'), 'cancelled');
const DeclinedPage = lazyWithRetry(() => import('@/pages/Declined'), 'declined');
const ErrorPage = lazyWithRetry(() => import('@/pages/ErrorPage'), 'error');
const PageNotFound = lazyWithRetry(() => import('@/pages/common/PageNotFound'), 'page-not-found');
const LoadingScreen = lazyWithRetry(() => import('@/pages/common/LoadingScreen'), 'loading-screen');

const App = () => (
  <Suspense fallback={<LoadingScreen />}>
    <EmergePayProvider>
      <div className="min-h-dvh h-dvh w-dvw min-w-dvw text-white font-sans">
        <Routes>
          <Route index element={<HomeFallbackPage />} />
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.success} element={<Success />} />
          <Route path={routes.cancelled} element={<CancelledPage />} />
          <Route path={routes.declined} element={<DeclinedPage />} />
          <Route path={routes.error} element={<ErrorPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </EmergePayProvider>
  </Suspense>
);

export default App;
