import React, { createContext, Dispatch, HTMLProps, SetStateAction, useState } from 'react';

type EmergePayDetails = {
  id: string;
  name: string;
  imgUrl?: string | null;
  amount?: string | null;
};

export const EmergePayContext = createContext<{
  emergePayDetails: EmergePayDetails | undefined;
  setEmergePayDetails: Dispatch<SetStateAction<EmergePayDetails | undefined>>;
  hash: string | undefined;
  setHash: Dispatch<SetStateAction<string | undefined>>;
} | null>(null);

const EmergePayProvider: React.FC<HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [details, setDetails] = useState<EmergePayDetails>();
  const [hash, setHash] = useState<string>();

  const value = {
    emergePayDetails: details,
    setEmergePayDetails: setDetails,
    hash,
    setHash
  };

  return <EmergePayContext.Provider value={value}>{children}</EmergePayContext.Provider>;
};

export default EmergePayProvider;
